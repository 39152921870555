.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #252329;
  overflow: hidden;
}

.logo {
  width: 350px;
  @media screen and (max-width: 350px) {
    width: 300px;
  }
}

.guest {
  color: #006ebd;
  font-size: 12px;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
  }
}
