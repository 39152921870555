body {
  background-color: #282c34;
}

.App {
  text-align: center;
  max-width: 728px;
  margin: 0 auto;
}

.App header {
  background-color: #181717;
  height: 10vh;
  color: white;
  position: fixed;
  width: 100%;
  max-width: 728px;
  top: 0;
  display: list-item;
  align-items: baseline;
  justify-content: space-between;
  z-index: 99;
  box-sizing: border-box;
}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 90vh;
  background-color: rgb(40, 37, 53);
}

.main {
  padding: 5px;
  height: 80vh;
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
  background: #6649b8;
}

form {
  height: 8vh;
  position: fixed;
  bottom: 0;
  background-color: rgb(40, 37, 53);
  width: 100%;
  max-width: 728px;
  display: flex;
  font-size: 1.0rem;
}

form button {
  width: 20%;
  background-color: rgb(56, 56, 143);
}

input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background: rgb(58, 58, 58);
  color: white;
  outline: none;
  border: none;
  padding: 0 10px;
}

button {
  background-color: #282c34; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sign-in {
  color: white;
  background: #282c34;
  border: white;
  border-width: 1px;
  border-radius: 10px;
  max-width: 400px;
  margin: 0 auto;
}

ul,
li {
  text-align: left;
  list-style: none;
}

p {
  max-width: 500px;
  margin-bottom: 5px;
  padding: 8px 8px;
  border-radius: 10px;
  position: relative;
  color: white;
  text-align: center;
}

.message {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0px;
}

.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: white;
  background: #1672f9;
  align-self: flex-end;
}
.received p {
  background: white;
  color: black;
}

img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}

.reply {
  background-color: #315ebe;
  opacity: .5;
  color: white;
  border-radius: 10px 10px 10px 10px;
  padding: 3px;
  margin-top: 10px;
}

.replyBlue {
  color: white;
  background-color: #064877;
  opacity: 0.7;
  border-radius: 10px 10px 10px 10px;
  padding: 3px;
  margin-top: 10px;
}
